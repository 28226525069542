<template>
    <div class="mod-body flex-column">
        <div class="table-panel-tabs" id="trade-pannel-tabs">
            <button class="btn color-up" :class="{ active: buyUp === true }" @click="buyUp = true">{{ $t('futures.label_buy_up') }}</button>
            <button class="btn color-down" :class="{ active: buyUp === false }" @click="buyUp = false">{{ $t('futures.label_buy_down') }}</button>
        </div>
        <form action="javascript:void(0);" method="post" class="order-options-form" @submit="doConfirmOrder">
            <div class="single-panel limit shadow-sm">
                <input type="hidden" name="SymbolId" :value="symbol.metadata.id" />
                <input type="hidden" name="buyUp" :value="buyUp" />

                <div class="row">
                    <div class="col">
                        <div class="d-flex flex-row text-label">
                            <div v-for="(val, index) in futuresConfig.supportedLeverages" :key="index" class="inline-radio flex-grow-1">
                                <input v-model="leverage" :id="'order-leverage-' + index" type="radio" name="leverage" :value="val" @change="leverageChange()"/>
                                <label :for="'order-leverage-' + index">{{ val }}X</label>
                            </div>
                        </div>
                    </div>
                </div>
                <div growing-ignore="true" class="row text-content">
                    <div class="col-auto color-up-lot">{{ $t('exchange.available_balance') }}</div>
                    <div class="col-auto" v-if="isNaN(availableMargin)">--</div>
                    <div class="col-auto" v-else>
                        {{ Math.max(0, availableMargin).toFixed(4) }} USDT
                        <span v-if="!isNaN(maxHands)"> / {{ maxHands }} {{ $t('futures.label_unit') }} </span>
                    </div>
                </div>
                <div class="row has-input">
                    <div class="col-auto text-label">
                        <span>{{ $t('futures.label_market_price') }}</span>
                    </div>
                    <label growing-ignore="true" class="col">
                        <input type="text" :value="symbol.price" readonly class="text-muted" />
                    </label>
                </div>
                <div class="row has-input">
                    <div class="col-auto text-label">{{ $t('futures.label_amount') }} ({{ $t('futures.label_unit') }})</div>
                    <label growing-ignore="true" class="col position-relative">
                        <input
                            type="number"
                            v-model="numHands"
                            name="num_hands"
                            maxlength="14"
                            autocomplete="off"
                            data-val="true"
                            data-val-number="*"
                            data-val-required="*"
                            data-val-range="*"
                            data-val-range-min="1"
                        />
                        <div class="action-links">
                            <a href="javascript:" class="action-link" @click="plusHands"><i class="fa fa-plus"></i></a>
                            |
                            <a href="javascript:" class="action-link" @click="minusHands"><i class="fa fa-minus"></i></a>
                        </div>
                    </label>
                </div>
                <div class="row total text-nowrap" growing-ignore="true">
                    <el-slider
                        class="el-slider-content"
                        v-model="percentValue"
                        :format-tooltip="formatTooltip"
                        :marks="marks"
                        @change="percentChange">
                    </el-slider>
                    <div class="slider-percentage">
                        <div v-for="(label, mark) in marks" :key="mark" class="min-wid">
                            <div v-if="mark">
                                {{ mark == 0? mark : mark+"%" }}
                            </div>
                        </div>
                    </div>
                    <div class="col-auto text-label">1 {{ $t('futures.label_unit') }} = {{ futuresConfig.orderUnit }} USDT</div>
                    <div class="text-label">
                        {{ $t('futures.label_margin') }}
                        <span class="total-value">{{ requiredMargin }} USDT</span>
                    </div>
                </div>
                <div class="row">
                    <div class="col">
                        <button v-if="buyUp" class="btn-submit bg-buy">{{ $t('futures.button_buy_up') }}</button>
                        <button v-else class="btn-submit bg-sell">{{ $t('futures.button_buy_down') }}</button>
                    </div>
                </div>
            </div>
        </form>
    </div>
</template>

<script>
import { Slider } from 'element-ui';
Vue.use(Slider)
export default {
    props: ['symbol', 'futuresConfig'],

    data() {
        return {
            buyUp: true,

            // The amount of available margin
            availableMargin: NaN,

            leverage: NaN,
            maxHands: NaN,

            // The number of hands to purchase.
            numHands: '',
            percentValue:0,
            marks: {
                0: "",
                20: "",
                40: "",
                60: "",
                80:"",
                100: ""
            }
        };
    },

    created() {
        this.leverage = this.futuresConfig.defaultLeverage;
    },

    computed: {
        requiredMargin: function () {
            const leverage = this.leverage * 1;
            if (Number.isInteger(leverage) && leverage > 0) {
                const margin = (this.numHands * this.futuresConfig.orderUnit) / leverage;
                return !isNaN(margin) && margin >= 0 ? Math.round(margin) : '--';
            } else {
                return '--';
            }
        }
    },

    watch: {
        leverage: function () {
            this._updateMaxHands();
        },

        availableMargin: function () {
            this._updateMaxHands();
        },

        numHands: function () {
            // Make sure to use integers
            if (this.numHands !== '') {
                const val = this.numHands * 1;
                const target = isNaN(val) || val < 1 ? 1 : Math.round(val);
                if (val !== target) {
                    this.numHands = target;
                }
            }
        }
    },

    mounted() {
        $.resetValidators();
        let that = this
        Vue.nextTick(() => {
            $(".el-slider__stop.el-slider__marks-stop").on('click',function(){
                let left = this.style.left
                let getNumber = parseFloat(left.replace('%', ''));
                setTimeout(()=>{
                    that.percentValue = getNumber
                    that.numHands = Math.round((getNumber*that.maxHands)/100)
                },0)
            })
        })
    },

    methods: {
        leverageChange(e){
            //slider reset value to zero
            this.percentValue = 0
            this.numHands = ''
        },
        percentChange(val){
            this.numHands = Math.floor((val*this.maxHands)/100)
        },
        formatTooltip(val) {
            return val +'%';
        },
        ///////////////////////////////////////////////////////////////////////////////////////
        /**
         * Update the amount of available margin.
         */
        setAvailableMargin: function (margin) {
            this.availableMargin = Math.max(0, margin);
        },

        setOrderType: function (up) {
            this.buyUp = up === true;
        },

        ///////////////////////////////////////////////////////////////////////////////////////
        /**
         * Compute the max amount of hands that could be purchased.
         */
        _updateMaxHands: function () {
            const leverage = this.leverage * 1;
            const margin = Math.max(0, this.availableMargin);
            this.maxHands = Math.floor(margin / (this.futuresConfig.orderUnit / leverage + this.futuresConfig.orderUnit * this.futuresConfig.openFeeRate));
        },

        ///////////////////////////////////////////////////////////////////////////////////////
        /**
         * Plus/minus hands
         */
        plusHands: function () {
            let n = this.numHands * 1;
            this.numHands = isNaN(n) ? 1 : Math.max(1, Math.round(n) + 1);
        },

        minusHands: function () {
            let n = this.numHands * 1;
            this.numHands = isNaN(n) || n <= 1 ? 1 : Math.round(n) - 1;
        },

        ///////////////////////////////////////////////////////////////////////////////////////
        /**
         * Confirm order.
         */
        doConfirmOrder: function (e) {
            const frm = $(e.target);
            if (frm.valid()) {
                this.$emit('create-order', {
                    buyUp: this.buyUp,
                    numHands: this.numHands * 1,
                    leverage: this.leverage * 1
                });
            }
        }
    }
};
</script>
<style lang="less" scoped>
.el-slider-content::v-deep {
    width: 99%;
    margin: 0 auto;
    .el-slider__button-wrapper {
        z-index: 9;
    }
    .el-slider__stop.el-slider__marks-stop {
        border: 2px solid rgb(234, 236, 239);
        width: 10px;
        height: 10px;
        border-radius: 0;
        margin-top: -2px;
        transform: rotate(45deg);
        background: rgb(71, 77, 87);

        &:hover {
            background-color: rgb(234, 236, 239);
            border-color: rgb(71, 77, 87);
        }
    }

    .el-slider__bar {
        background-color: rgb(71, 77, 87);
    }

    .el-slider__button {
        border-color: rgb(71, 77, 87);
    }

    .el-slider-content .el-slider__stop.el-slider__marks-stop {
        &:hover {
            background: rgb(234, 236, 239);
        }
    }
}

.slider-percentage {
    display: flex;
    justify-content: space-around;
    font-size: 12px;
    color: #333;
    line-height: 12px;
    width: 100%;
    margin-left: 10px;
}
.min-wid{
    width: 16%;
    text-align: right;
}
.min-wid:first-child {
    text-align: left;
    text-indent: -10px;
}
.min-wid:nth-child(2) {
    text-align: left;
    text-indent: -8px;
}
.min-wid:nth-child(3) {
    text-align: left;
}
.min-wid:nth-child(4) {
    text-align: center;
}

@media (max-width:767px) {
    .el-slider-content::v-deep {
        .el-slider__stop.el-slider__marks-stop {
            border: 2px solid rgb(234, 236, 239);
            width: 8px;
            height: 8px;
        }
    }
}
</style>