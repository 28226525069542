<template>
  <section class="page login-page layout-login d-flex">
    <div class="d-flex flex-row login-left-right flex-fill">
      <!-- <div class="login-page-left">Left component</div> -->
      <div class="login-page-right login-container">
        <div>
          <div class="login-box">
            <div class="login container">
              <div class="login-inner" id="login-page">
                <div class="row">
                  <div class="col">
                    <h1>{{ $t("login.title") }}</h1>
                  </div>
                </div>
                <login-form-component
                  v-if="regions"
                  v-bind:regions="regions"
                  v-bind:csrfToken="csrfToken"
                />
                <loading-indicator v-else />
              </div>
            </div>
          </div>
          <div class="img">
            <div class="right-content">
              <img
                class="img-award"
                src="@/assets\images\ko-5\award.png"
                alt=""
              />
              <h3 class="right-title">
                {{ $t("coin_treasure.level_reward") }}
              </h3>
              <div class="right-boby">
                <div class="right-img">
                  <img src="@/assets\images\ko-5\receive.png" alt="" />
                </div>
                <div>{{ $t("exchange.type_limited_price") }}</div>
                <div>{{ $t("futures.invalid_stop_points") }}</div>
              </div>
              <div class="right-boby">
                <div class="right-img">
                  <img src="@/assets\images\ko-5\mining.png" alt="" />
                </div>
                <div>{{ $t("exchange.type_limited_price") }}</div>
                <div>{{ $t("futures.invalid_stop_points") }}</div>
              </div>
              <div class="right-boby">
                <div class="right-img">
                  <img src="@/assets\images\ko-5\way.png" alt="" />
                </div>
                <div>{{ $t("exchange.type_limited_price") }}</div>
                <div>{{ $t("futures.invalid_stop_points") }}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<style scoped lang="less">
.login-left-right {
  margin: auto;
  width: 100%;
}
.login-container {
  background: #fff;
}
.login-page .login h1 {
  font-family: PingFang SC, PingFang SC;
  font-weight: 600;
  font-size: 26px;
  color: #3d3d3d;
  text-align: start;
  padding-top: 0;
  padding-bottom: 4rem;
}
.login-container > div img {
  width: initial;
  height: initial;
  margin-left: 0;
  margin-right: 0;
}
.img,
.login-box {
  width: 50%;
  height: 100%;
}
.img {
  text-align: center;
  padding-right: 10%;
}
.login-box {
  padding-left: 10%;
}
.right-content {
  background: #f8f9ff;
  position: relative;
  width: 320px;
  min-height: 550px;
  display: inline-block;
  margin-top: 200px;
  padding: 15px;
  margin-bottom: 100px;
}
.img-award {
  position: absolute;
  top: -162px;
  right: 20%;
}
.right-title {
  font-family: PingFangSC, PingFang SC;
  font-weight: 600;
  font-size: 24px;
  color: #3d3d3d;
  border-bottom: 1px solid #d8d8d8;
  min-height: 40px;
  margin-top: 110px;
}
.right-boby {
  margin-top: 10px;
}
.right-boby > div {
  text-align: left;
  padding: 2px 10px;
}
.right-boby > div:nth-child(2) {
  font-family: PingFangSC, PingFang SC;
  font-weight: 600;
  font-size: 16px;
  color: #2c2236;
}
.right-boby > div:nth-child(3) {
  font-family: PingFangSC, PingFang SC;
  font-weight: 500;
  font-size: 12px;
  color: #2c2236;
}
.login-container > div {
  height: 100%;
}
@media (max-width: 1024px) {
  .login-box {
    min-width: inherit;
    width: 100%;
  }
  .login-container {
    background: #fff;
  }

  .login-container .img {
    display: none;
  }


  .login-page .login h1 {
    color: #000;
  }

}
</style>

<script type="text/javascript">
import LoginFormComponent from "./Components/LoginForm.vue";
import { getAppContext } from "utilities/helper";

export default {
  components: { LoginFormComponent },
  data() {
    return { regions: null, csrfToken: null };
  },

  created() {
    // Already signed in?
    const context = getAppContext();
    const profile = context.profile;
    if (profile) {
      this.$router.push("/user");
    } else {
      this.initAsync();
    }
  },

  watch: {
    "$i18n.locale": function () {
      this.reloadRegionsAsync();
    }
  },

  methods: {
    initAsync: async function () {
      const self = this;

      // Read a list of supported regions.
      const resp = await $.callGetApi(self, "/api/v1/config/regions");

      // Read csrf token
      const token = await $.callPostApi(self, "/api/v1/csrftoken");
      if (token && typeof token === "string") {
        self.regions = resp.data;
        self.csrfToken = token;
      }
    },

    reloadRegionsAsync: async function () {
      // Read a list of supported regions.
      const resp = await $.callGetApi(self, "/api/v1/config/regions");
      if (resp && resp.data) {
        this.regions = resp.data;
      }
    }
  }
};
</script>