import { render, staticRenderFns } from "./_BalanceList-MultiCoins.vue?vue&type=template&id=08c28f35&scoped=true"
import script from "./_BalanceList-MultiCoins.vue?vue&type=script&lang=js"
export * from "./_BalanceList-MultiCoins.vue?vue&type=script&lang=js"
import style0 from "./_BalanceList-MultiCoins.vue?vue&type=style&index=0&id=08c28f35&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "08c28f35",
  null
  
)

export default component.exports