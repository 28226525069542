import { render, staticRenderFns } from "./HomeNotices.vue?vue&type=template&id=1ba17896&scoped=true"
import script from "./HomeNotices.vue?vue&type=script&lang=js"
export * from "./HomeNotices.vue?vue&type=script&lang=js"
import style0 from "./HomeNotices.vue?vue&type=style&index=0&id=1ba17896&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1ba17896",
  null
  
)

export default component.exports