<template>
    <table class="table list-table stock-list-table">
        <thead>
            <tr>
                <td class="text-nowrap">{{ $t('balance.label_order_type') }}</td>
                <td class="text-right">{{ $t('balance.label_order_amount') }}</td>
                <td>{{ $t('balance.label_order_status') }}</td>
                <td>{{ $t('balance.label_order_time') }}</td>
            </tr>
        </thead>
        <tbody v-if="!orders">
            <tr>
                <td colspan="4">
                    <loading-indicator />
                </td>
            </tr>
        </tbody>
        <tbody v-else>
            <tr v-if="orders.length === 0">
                <td colspan="4">
                    <div class="text-muted">{{ $t('general.no_data') }}</div>
                </td>
            </tr>
            <tr v-for="order in orders" v-bind:key="order.id">
                <td>{{ order.amount > 0 ? $t('balance.order_type_deposit') : $t('balance.order_type_withdraw') }}</td>
                <td class="text-right">{{ order.currencyType === 'FTUSDT' ? Math.abs(order.amount) + ' ' + $t('assets.label_futures_account') : Math.abs(order.amount) + ' ' + order.currencyType }}</td>
                <td>
                    <span v-if="order.status === DepositOrderStatus.PendingForApproval">{{ $t('balance.order_status_pending') }}</span>
                    <span v-else-if="order.status === DepositOrderStatus.Processing || order.status === DepositOrderStatus.Submitted">
                        {{ $t('balance.order_status_processing') }}
                    </span>
                    <span v-else-if="order.status === DepositOrderStatus.Completed">{{ $t('balance.order_status_completed') }}</span>
                    <span v-else-if="order.status === DepositOrderStatus.Cancelled">{{ $t('balance.order_status_cancelled') }}</span>
                    <span v-else>{{ $t('balance.order_status_other') }}</span>
                </td>
                <td>{{ new Date(order.timeCreated).formatDateTime() }}</td>
            </tr>
            <tr v-if="has_more_orders">
                <td colspan="4">
                    <button class="btn btn-default btn-block text-muted" v-on:click="query_data()">{{ $t('balance.label_next_page') }}</button>
                </td>
            </tr>
        </tbody>
    </table>
</template>
<script>
export default {
    data() {
        return {
            orders: null,
            has_more_orders: false,
            until_ms: NaN,

            DepositOrderStatus: Object.freeze({
                Processing: 2,
                Completed: 3,
                Submitted: 5,
                Cancelled: 6,
                PendingForApproval: 8
            })
        };
    },

    created() {
        // use a future time to make sure include most recent orders.
        this.until_ms = new Date().valueOf() + 30 * 2400 * 1000;
        this.query_data();
    },

    methods: {
        ///////////////////////////////////////////////////////////////////////////////////////////
        // Query records
        ///////////////////////////////////////////////////////////////////////////////////////////
        query_data: function () {
            const self = this;
            self.orders = null;

            $.callPostApi(self, '/api/v1/finance/orders?until=' + encodeURIComponent(self.until_ms))
                .then((json) => {
                    self.orders = json.data;
                    if (json.data && json.data.length) {
                        self.until_ms = json.data[json.data.length - 1].timeCreated;
                    }
                    self.has_more_orders = json.data.length === json.page_size;
                })
                .catch((err) => {
                    console.error(`ERROR: ${err}`);
                    $.top_error(self.$t('general.http_error'));
                });
        }
    }
};
</script>