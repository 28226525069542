
import axios from 'axios';
import { getAppContext } from 'utilities/helper';


/**
 * Create a SymbolStore object.
 * @param {Array} symbols An array of symbols.
 */
const SymbolStore = function (symbols) {
    if (!symbols || !Array.isArray(symbols))
        throw new Error('Expected an array of symbols.');

    // Integer id map, e.g. 201 --> { name: 'BTC/USDT' }
    const _integerIdMap = {};

    // Symbold id map, e.g. btc_usdt --> { name: 'BTC/USDT' }
    const _stringIdMap = {};

    // Type map
    const _typeMap = {};

    // All symbols.
    const _allSymbols = Object.freeze(symbols);

    function getSymbolKey(type, symbol) {
        return type + '/' + symbol.toLowerCase();
    }

    ///////////////////////////////////////////////////////////////////////////////////////////////
    // Build all the maps
    for (let i = 0; i < symbols.length; i++) {
        const sym = Object.freeze(symbols[i]);
        _integerIdMap[sym.id] = sym;
        _stringIdMap[getSymbolKey(sym.type, sym.symbol)] = sym;

        let arr = _typeMap[sym.type];
        if (!arr) {
            arr = [];
            _typeMap[sym.type] = arr;
        }
        arr.push(sym);
    }

    ///////////////////////////////////////////////////////////////////////////////////////////////
    // Public functions
    //
    this.getSymbols = function (type) {
        if (!Number.isInteger(type)) {
            throw new Error(`Invalid symbol type: ${type}.`);
        }

        const output = _typeMap[type];
        if (output && output.length > 0) {
            // Determines if test mode is enabled or not for the current user.
            const context = getAppContext();
            let testMode = false;
            if (context && context.profile) {
                testMode = context.profile.testEnabled === true;
            }

            // Filter output all test symbols if test mode isn't enabled for the current user
            if (testMode)
                return output;
            else
                return output.filter(s => s.testOnly !== true);
        } else {
            return output;
        }
    };

    ///////////////////////////////////////////////////////////////////////////////////////////////
    /**
     * Search symbols using the specified filter.
     * @param {*} filter 
     */
    this.searchSymbols = function (type, filter) {
        if (!Number.isInteger(type)) {
            throw new Error(`Invalid symbol type: ${type}.`);
        }

        if (typeof filter !== 'string')
            filter = '' + filter;

        filter = filter.trim().toUpperCase();
        if (filter.length === 0) {
            const symbols = _typeMap[type];
            return symbols ? symbols.slice(0, 10) : [];
        } else {
            const symbols = _typeMap[type];
            return symbols ? symbols.filter(s => {
                return s.name.indexOf(filter) >= 0;
            }).slice(0, 10) : [];
        }
    };

    this.getSymbol = function (key, type) {
        if (!key) throw new Error('The key parameter is required.');
        if (typeof key === 'number')
            return _integerIdMap[key];

        let symbol = null;
        if (typeof type === 'number') {
            key = getSymbolKey(type, key);
            symbol = _stringIdMap[key] || _integerIdMap[key * 1];
        } else {
            symbol = _integerIdMap[key * 1];
        }

        if (symbol) {
            // Determines if test mode is enabled or not for the current user.
            const context = getAppContext();
            let testMode = false;
            if (context && context.profile) {
                testMode = context.profile.testEnabled === true;
            }

            if (testMode || !symbol.testEnabled) {
                return symbol;
            }
        }
        return null;
    };

    /**
     * Returns all symbols.
     * @returns An array of symbols.
     */
    this.getAllSymbols = function () {
        return _allSymbols;
    };
}

let _symbols_timer_id = 0;


/**
 * Create a symbol manager.
 * @param {Array} symbols An array of symbols.
 */
const SymbolManager = function (store) {
    if (!store || typeof store.getSymbols !== 'function')
        throw new Error('Invalid symbol store');

    // Save the initial store.
    let _store = store;

    ///////////////////////////////////////////////////////////////////////////////////////////////
    // Start to sync symbols every 5 minutes.
    //
    function syncSymbols() {
        createSymbolStoreAsync().then(store => {
            _store = store;
        }).catch(err => {
            console.error(`Failed to sync symbols: ${err}`);
        }).then(() => {
            clearTimeout(_symbols_timer_id);
            _symbols_timer_id = setTimeout(syncSymbols, 300 * 1000);
        });
    };
    clearTimeout(_symbols_timer_id);
    _symbols_timer_id = setTimeout(syncSymbols, 300 * 1000);


    ///////////////////////////////////////////////////////////////////////////////////////////////
    // Public functions
    //
    this.getSymbols = function (type) {
        return _store.getSymbols(type);
    };

    this.getSymbol = function (key, type) {
        return _store.getSymbol(key, type);
    };

    this.getAllSymbols = function () {
        return _store.getAllSymbols();
    }
    this.searchSymbols = function (type, filter) {
        return _store.searchSymbols(type, filter);
    }
}


/**
 * Query symbols from server.
 * @returns A SymbolStore object.
 */
async function createSymbolStoreAsync() {
    const resp = await axios.get(g_server_root + '/api/v1/quotation/symbols');
    if (resp && resp.data) {
        return new SymbolStore(resp.data);
    } else {
        throw new Error('Failed to query symbols.');
    }
}

export async function createSymbolManagerAsync() {
    const store = await createSymbolStoreAsync();
    return new SymbolManager(store);
}