<template>
    <div class="page">
        <div class="earn-main earn-history-main">
            <div class="top earn-top-bg">
                <div class="back-btn">
                    <div class="arrow" @click="goBack">{{ $t('coin_treasure.return') }}</div>
                    <div class="history-btn" @click="goToAccount">{{ $t('coin_treasure.account') }}</div>
                </div>
                <div class="title earn-top-link">
                    <h2 class="h2">{{ $t('coin_treasure.financial_history') }}</h2>
                    <div class="history-btn" @click="goToAccount">{{ $t('coin_treasure.account') }}</div>
                </div>
            </div>

            <div class="earn-table">
                <div class="tabs-con">
                    <!-- <div class="tab">
                        <div :class="{ active: selected_type === 1 }">
                            <a href="javascript:;" @click="selected_type = 1">{{$t('coin_treasure.current')}}</a>
                        </div>
                        <div :class="{ active: selected_type === 2 }">
                            <a href="javascript:;" @click="selected_type = 2">{{$t('coin_treasure.regular')}}</a>
                        </div>
                    </div> -->
                </div>

                <List :tableData='currentList' :type="selected_type" />

                <Card :tableData='currentList' :type="selected_type" :body='tableData' />


                <div class="loading-box" v-if="isLoadCurrentList">
                    <loading-indicator />
                </div>
                <div class="col" v-if="!isLoadCurrentList && currentList.length == 0">
                    <div class="no-data-indicator m-5">{{ $t('orders.no_orders') }}</div>
                </div>

                <div v-if="!isLoadCurrentList && hasData" class="more-btn" @click="loadMoreData">
                    {{ $t('coin_treasure.load_more') }} >>
                </div>
            </div>

        </div>

    </div>
</template>

<script>

// import Coin from '../Components/Coin.vue';
import List from './Components/HistoryList.vue';
import ListMobile from './Components/ListMobile.vue';
import Card from './Components/Card.vue';
import { savingHistory } from './request'
export default {
    components: { List, ListMobile, Card },
    data() {
        return {
            list: [],
            selected_type: 1,
            value: -1996.37,
            currentList: [],
            regularList: [],
            isLoadCurrentList: true,
            hasData: true,
            tableData: [
                { title: 'coin_treasure.currency', value: 'token' },
                { title: 'coin_treasure.subscription_date', value: 'timeCreated' },
                { title: 'coin_treasure.lump_sum', value: 'amount' },
                { title: 'coin_treasure.cumulative_income', value: 'interests' },
                { title: 'coin_treasure.level_reward', value: 'extraInterests' },
                // {title:'coin_treasure.maturity_date',value:'timeUpdated'},
                { title: 'coin_treasure.state', value: 'status' },
                // {title:'',value:'status',hasClick:true},

            ]
        };
    },
    created() {
        this.getHistory()
    },
    mounted() {

    },
    methods: {
        goBack() {
            window.history.go(-1)
        },
        goToAccount() {
            this.$router.push('/saving/account')
        },
        getHistory(id = 0x7fffffff) {
            let that = this
            savingHistory(id).then(res => {
                // 取消loading
                that.isLoadCurrentList = false

                if (res.data.errcode !== 0) {
                    return false
                }
                let data = res.data.data
                if (data.length < 20) {
                    that.hasData = false
                } else {
                    that.hasData = data[data.length - 1].uid
                }
                this.formData(data)
            })

        },
        clickFunc(data) {
            console.log(data);
        },
        formData(data) {
            let currentList = this.currentList
            data.forEach(item => {
                currentList.push(item)
            });
            this.currentList = currentList
            // //分成两组数组活期和定期
            // let currentList = data
            // let regularList = []
            // data.forEach(item => {
            //     if (item.numDays === 0) {
            //         currentList.push(item)
            //     } else {
            //         regularList.push(item)
            //     }
            // })
            // this.currentList = currentList
            // this.regularList = regularList
        },
        redeemDialog() {
            this.showDialog()
        },
        loadMoreData() {
            // alert(this.hasData)
            if (this.hasData) {
                this.isLoadCurrentList = true
                this.getHistory(this.hasData)
            }
        },
        showDialog: function () {
            this.$refs.earnModal.showModal();
        }
    },
};
</script>
