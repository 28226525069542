<template>
    <section class="page orders-page">
        <div class="container">
            <div class="row">
                <div class="col">
                    <h1>{{ $t('home.header_boption_orders') }}</h1>
                </div>
            </div>
            <div class="row">
                <div class="col">
                    <ul class="page-top-nav">
                        <li>
                            <router-link class="nav-link" to="/boption/openorders">{{ $t('orders.open_orders') }}</router-link>
                        </li>
                        <li class="active">
                            <router-link class="nav-link active" to="/boption/orders">{{ $t('orders.all_orders') }}</router-link>
                        </li>
                    </ul>
                </div>
            </div>
            <div v-if="!orders" class="row">
                <div class="col">
                    <loading-indicator />
                </div>
            </div>
            <template v-else>
                <div v-if="orders.length === 0" class="row">
                    <div class="col">
                        <div class="no-data-indicator m-5">{{ $t('orders.no_orders') }}</div>
                    </div>
                </div>
                <div class="row">
                    <div class="col">
                        <div class="order-item" v-bind:key="order.orderId" v-for="order in orders">
                            <div class="row">
                                <div class="col">
                                    <label>{{ $t('orders.label_symbol') }}</label> {{ order.symbolName }} {{ order.duration }}S
                                    <span class="badge bg-danger" v-if="order.status === 1">{{ $t('orders.status_open') }}</span>
                                    <span class="badge bg-danger" v-if="order.status === 4 && order.errorCode !== 0">{{ $t('orders.status_invalid') }}</span>
                                    <span class="badge bg-danger" v-if="order.status === 5">{{ $t('orders.status_failed') }}</span>
                                </div>
                                <div class="col">
                                    <label>{{ $t('orders.label_direction') }}</label>
                                    <span v-if="order.buyUp" class="color-up">{{ $t('orders.label_direction_up') }} <i class="fa fa-arrow-up"></i></span>
                                    <span v-else class="color-down">{{ $t('orders.label_direction_down') }} <i class="fa fa-arrow-down"></i></span>
                                </div>
                            </div>

                            <div class="row">
                                <div class="col">
                                    <label>{{ $t('orders.label_create_price') }}</label> {{ order.createPrice.toFixed(order.pricePrecision) }}
                                </div>
                                <div class="col">
                                    <label>{{ $t('orders.label_currency') }}</label>
                                    {{ order.currency === 'USDT' ? $t('orders.label_currency_cash') : $t('orders.label_currency_coupon') }}
                                </div>
                            </div>

                            <div class="row">
                                <div class="col">
                                    <label>{{ $t('orders.label_amount') }}</label> {{ order.amount }}{{ currencySymbol }}
                                </div>
                                <div class="col">
                                    <label>{{ $t('orders.label_fee') }}</label> {{ order.fee.toFixed(2) }}
                                </div>
                            </div>
                            <div class="row" v-if="order.status === 4 && order.errorCode === 0">
                                <div class="col">
                                    <label>{{ $t('orders.label_close_price') }}</label> {{ order.closePrice.toFixed(order.pricePrecision) }}
                                </div>
                                <div class="col">
                                    <label>{{ $t('orders.label_profit') }}</label> {{ order.profit.toFixed(2) }}
                                </div>
                            </div>
                            <div class="row">
                                <div class="col">
                                    <label>{{ $t('orders.label_create_time') }}</label> {{ new Date(order.timeCreated).formatDateTime(true) }}
                                </div>
                            </div>
                            <div class="row" v-if="order.status === 4">
                                <div class="col">
                                    <label>{{ $t('orders.label_close_time') }}</label> {{ new Date(order.timeClosing).formatDateTime(true) }}
                                </div>
                            </div>
                        </div>

                        <!-- loader -->
                        <loading-indicator v-if="loading" />
                        <div class="row" v-if="!loading && has_more">
                            <div class="col text-center">
                                <a href="javascript:" class="d-block text-muted p-3" v-on:click="getOrdersAsync">{{ $t('orders.query_more_orders') }}</a>
                            </div>
                        </div>
                    </div>
                </div>
            </template>
        </div>
    </section>
</template>

<script>
export default {
    data() {
        return {
            orders: null,
            has_more: false,
            offset: 0,

            // Indicate if we're reading data from the server?
            loading: false
        };
    },

    created() {
        this.getOrdersAsync();
    },

    methods: {
        getOrdersAsync: async function () {
            try {
                ///////////////////////////////////////////////////////////////////////////////////
                // Read all orders
                ///////////////////////////////////////////////////////////////////////////////////
                this.loading = true;
                const json = await $.callPostApi(this, '/api/v1/boptionorder/orders?offset=' + this.offset);
                if (typeof json === 'undefined') {
                    return; // Needs authentication
                }

                if (json.errcode === 0) {
                    if (!this.orders) {
                        this.orders = [];
                    }

                    for (let i = 0; i < json.data.length; i++) {
                        this.orders.push(json.data[i]);
                    }
                    this.offset = json.offset + json.data.length;
                    this.has_more = json.data.length >= json.page_size;
                } else {
                    $.top_error(json.errmsg);
                }

                this.loading = false;
            } catch (err) {
                this.loading = false;
                $.top_error(this.$t('general.http_error'));
            }
        }
    }
};
</script>