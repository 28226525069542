<template>
  <div class="login container">
    <div class="login-inner">
      <form
        action="javascript:void(0);"
        method="post"
        class="login-form"
        @submit="do_submit"
      >
        <h1>{{ $t("reset_password.title") }}</h1>
        <div class="form-group">
          <label>{{ $t("reset_password.label_account") }}</label>
          <div class="d-flex">
            <region-selector
              v-bind:regions="regions"
              :regionCode="regionCode"
              @change="onRegionCodeChanged"
            />
            <div class="flex-fill">
              <input
                name="Name"
                ref="accountInput"
                v-model="account"
                pattern="\d*"
                type="text"
                class="form-control phone round-right"
                data-val="true"
                data-val-required="*"
                :placeholder="$t('reset_password.label_account')"
              />
            </div>
          </div>
        </div>

        <div class="form-group">
          <label>{{ $t("reset_password.label_password") }}</label>
          <div class="password-box">
            <input
              name="Password"
              v-model="password"
              :type="showPassword ? 'text' : 'password'"
              autocomplete="new-password"
              class="form-control"
              data-val="true"
              data-val-required="*"
              :placeholder="$t('reset_password.label_password')"
            />
            <svg
              v-show="!showPassword"
              viewBox="0 0 256 256"
              class="svg-icon password-icon"
              @click="showPassword = !showPassword"
            >
              <use xlink:href="/dist/svg/icons.svg#v4.2-hidePassword" />
            </svg>
            <svg
              v-show="showPassword"
              viewBox="0 0 256 256"
              class="svg-icon password-icon"
              @click="showPassword = !showPassword"
            >
              <use xlink:href="/dist/svg/icons.svg#v4.2-showPassword" />
            </svg>
          </div>
        </div>
        <div class="form-group">
          <label>{{ $t("reset_password.label_confirm_password") }}</label>
          <div class="password-box">
            <input
              name="ConfirmPassword"
              v-model="confirmPassword"
              :type="showConfirmPassword ? 'text' : 'password'"
              autocomplete="new-password"
              class="form-control"
              data-val="true"
              data-val-required="*"
              data-val-equalto-other="Password"
              data-val-equalto="*"
              :placeholder="$t('reset_password.label_confirm_password')"
            />
            <svg
              v-show="!showConfirmPassword"
              viewBox="0 0 256 256"
              class="svg-icon password-icon"
              @click="showConfirmPassword = !showConfirmPassword"
            >
              <use xlink:href="/dist/svg/icons.svg#v4.2-hidePassword" />
            </svg>
            <svg
              v-show="showConfirmPassword"
              viewBox="0 0 256 256"
              class="svg-icon password-icon"
              @click="showConfirmPassword = !showConfirmPassword"
            >
              <use xlink:href="/dist/svg/icons.svg#v4.2-showPassword" />
            </svg>
          </div>
        </div>
        <sms-verification
          :regionCode="regionCode"
          :account="account"
          action="reset-password"
        />
        <div class="form-group">
          <button
            type="submit"
            class="btn btn-submit"
            :class="{ 'btn-completed': completed, 'btn-loading': submitting }"
            :disabled="submitting || completed"
          >
            {{ $t("reset_password.title") }}
          </button>
        </div>
      </form>
    </div>
    <div class="img">
      <div class="right-content">
        <img class="img-award" src="@/assets\images\ko-5\award.png" alt="" />
        <h3 class="right-title">{{ $t("coin_treasure.level_reward") }}</h3>
        <div class="right-boby">
          <div class="right-img">
            <img src="@/assets\images\ko-5\receive.png" alt="" />
          </div>
          <div>{{ $t("exchange.type_limited_price") }}</div>
          <div>{{ $t("futures.invalid_stop_points") }}</div>
        </div>
        <div class="right-boby">
          <div class="right-img">
            <img src="@/assets\images\ko-5\mining.png" alt="" />
          </div>
          <div>{{ $t("exchange.type_limited_price") }}</div>
          <div>{{ $t("futures.invalid_stop_points") }}</div>
        </div>
        <div class="right-boby">
          <div class="right-img">
            <img src="@/assets\images\ko-5\way.png" alt="" />
          </div>
          <div>{{ $t("exchange.type_limited_price") }}</div>
          <div>{{ $t("futures.invalid_stop_points") }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import RegionSelector from "../../Components/RegionSelectorV2.vue";
import "@/assets/svg/v4.2/v4.2-hidePassword.svg";
import "@/assets/svg/v4.2/v4.2-showPassword.svg";
export default {
  components: { RegionSelector },
  props: ["regions"],
  data() {
    return {
      account: null,
      regionCode: null,
      password: null,
      confirmPassword: null,

      showInputs: true,

      submitting: false,
      completed: false,
      showPassword: false,
      showConfirmPassword: false
    };
  },

  mounted() {
    $.resetValidators();
  },

  methods: {
    // goNextPage: function (e) {
    //     const frm = $(e.target);
    //     if (frm.valid()) {
    //         this.showInputs = false;
    //     }
    //     return false;
    // },

    // goPrevPage: function () {
    //     this.showInputs = true;
    // },

    do_submit: function (e) {
      const frm = $(e.target);
      const self = this;
      if (self.password !== self.confirmPassword) {
        $.top_error(self.$t("withdraw_password.err_different_passwords"));
        return;
      }
      if (frm.valid()) {
        this.submitting = true;

        const data = frm.serialize();
        $.callPostApi(self, "/api/v1/account/resetpassword", data)
          .then((json) => {
            if (json && json.errcode === 0) {
              // redirect to home page
              self.completed = true;

              setTimeout(function () {
                self.$router.push("/user");
              }, 500);
            } else {
              $.top_error(json.errmsg);
            }
          })
          .catch(function (err) {
            $.top_error(self.$t("general.operation_error"));
          })
          .then(() => {
            self.submitting = false;
          });
      }
    },

    focusAccount: function () {
      this.$refs.accountInput.focus();
    },

    onRegionCodeChanged: function (val) {
      this.regionCode = val;
    }
  }
};
</script>
<style scoped>
.login .form-control {
  border-radius: 20px;
  background: #fff;
}
.login .form-control:focus {
  border: 1px solid #935bca;
}
.login-page .login .login-inner {
  padding-left: 14%;
}
.login {
  width: 100%;
  height: 100%;
  display: flex;
  max-width: 100%;
}
.img,
.login-inner {
  width: 50%;
  height: 100%;
  min-width: 550px;
}
.img {
  text-align: center;
  padding-right: 6%;
}
.login-inner .login-form {
  max-width: 450px;
  width: 400px;
}
.page button.btn-submit {
  background: #935bca;
  color: #fff;
}
.right-content {
  background: #f8f9ff;
  position: relative;
  width: 320px;
  min-height: 550px;
  display: inline-block;
  margin-top: 98px;
  padding: 15px;
  margin-bottom: 100px;
}
.img-award {
  position: absolute;
  top: -162px;
  right: 20%;
}
.right-title {
  font-family: PingFangSC, PingFang SC;
  font-weight: 600;
  font-size: 24px;
  color: #3d3d3d;
  border-bottom: 1px solid #d8d8d8;
  min-height: 40px;
  margin-top: 110px;
}
.right-boby {
  margin-top: 10px;
}
.right-boby > div {
  text-align: left;
  padding: 2px 10px;
}
.right-boby > div:nth-child(2) {
  font-family: PingFangSC, PingFang SC;
  font-weight: 600;
  font-size: 16px;
  color: #2c2236;
}
.right-boby > div:nth-child(3) {
  font-family: PingFangSC, PingFang SC;
  font-weight: 500;
  font-size: 12px;
  color: #2c2236;
}
.password-box {
  position: relative;
}
.password-icon {
  position: absolute;
  right: 15px;
  bottom: 50%;
  transform: translateY(50%);
  cursor: pointer;
}
@media (max-width: 1024px) {
  .login-inner {
    width: 100%;
    min-width: 350px;
    display: flex;
    justify-content: center;
  }
  .login-page .login .login-inner {
    padding-left: 0px;
  }
  .img {
    display: none;
  }
  .login-inner .login-form {
    width: 320px;
  }
}
</style>