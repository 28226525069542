<template>
    <div class="container">
        <div class="buy-coins">
            <div class="coins-left">
                <h2>{{$t('home.home_guide')}}</h2>
                <hr />
                <div class="bottom-title">{{$t('home.home_createAccount')}}</div>
                <div class="content">{{$t('home.home_accountEasily')}}</div>
                <div class="bottom-title">{{$t('home.home_recharge')}}</div>
                <div class="content">{{$t('home.home_cryptocurrency')}}</div>
                <div class="bottom-title">{{$t('home.home_start_trading')}}</div>
                <div class="content">{{$t('home.home_possibilities')}}</div>
            </div>
            <div class="coins-img">
                 <img  src="@/assets\images\ko-5\coins_img.png" alt="" />
            </div>
        </div>
        <div class="manage-money">
            <div class="title"> {{ $t('home.home_choose') }}</div>
            <div class="content">
                <div class="money-left">
                    <div class="text">
                        <div class="bottom-title">{{$t('home.home_assetsProtected')}}</div>
                        <div class="content">{{$t('home.home_comprehensive')}}</div>
                        <div class="jumps">
                            <router-link to="/quotes" class="d-flex align-items-center">
                                {{ $t('home.label_more')+" >" }}
                            </router-link>
                        </div>
                    </div>                
                    <div class="bgimg"></div>
                </div>
                <div class="money-right">
                    <div class="text">
                        <div class="bottom-title">{{$t('home.home_anywhere')}}</div>
                        <div class="content">{{$t('home.home_quicklysafer')}}</div>
                        <div class="jumps">
                            <router-link to="/quotes" class="d-flex align-items-center">
                                {{ $t('home.label_more')+" >" }}
                            </router-link>
                        </div>
                    </div>                
                    <div class="bgimg"></div>
                </div>
            </div>
        </div>
        <div class="palm-quotes">
            <h2>{{ $t('home.home_yourside') }}</h2>
            <div class="quotes-content">{{$t('home.home_customer_service')}}</div>
            <img  src="@/assets\images\ko-5\quotes_img.png" alt="" />
        </div>
        <div class="palm-bottom">
            <h2>{{ $t('home.home_cryptocurrency_journey') }}</h2>
            <div class="quotes-content">{{$t('home.home_purchase')}}</div>
        </div>
    </div>
</template>
<script>
import Deposit from '../../Finance/Deposit.vue'
export default {
  components: { Deposit },
    data() {
        return {
           
        }
    },
    created() { },
    methods: {

    }
}
</script>
<style lang="less" scoped>
.container{
    width: 100%;
    margin-top: 50px;
    .buy-coins{
        width: 100%;
        min-height: 450px;
        display: flex;
        padding: 0 10px;
        background-image: url('@/assets/images/ko-5/coins_bg.png');
        background-size: 100% 100%;
        background-size: cover;
        .coins-left,.coins-img{
            width: 50%;
        }
        .coins-left{
            h2{
                margin-top: 12px;
                margin-bottom: 20px;
                font-weight: 600;
            }
            .descript{
                margin-bottom: 15px;  
            }
            hr {
                margin: 1.8rem 0;
            }
            .bottom-title{
                font-family: PingFang SC, PingFang SC;
                font-weight: 600;
                font-size: 18px;
                color: #000000;
                margin-bottom: 14px;
            }
            .content{
                margin-bottom: 20px;
            }
            .jumps a{
                width: 100px;
                height: 36px;
                border-radius: 20px;
                border: 1px solid #BAB9D0;
                font-family: PingFang SC, PingFang SC;
                font-weight: 600;
                font-size: 14px;
                color: #000000;
                display: flex;
                align-items: center;
                justify-content: center;
            }
            .jumps a:hover{
                color: #7637BB;
            }
        }
        .coins-img{
            text-align: center;
            padding: 10px;
            img{
                width: 280px;
                height: 400px;
            }
        }
    }
    .manage-money{
        margin-top: 40px;
        .title{
            width: 100%;
            font-family: PingFang SC, PingFang SC;
            font-weight: 600;
            font-size: 26px;
            text-align: center;
            margin-bottom: 30px;
        }
        .content{
            width: 100%;
            display: flex;
        }
        .content>div{
            width: 45%;
            min-height:400px;
            // border: 1px solid #BAB9D0;
        }
        .money-left{
            margin-left: 4%;
            margin-right: 2%;
            background-image: url('@/assets/images/ko-5/money_left.png');
            background-size: 100% 100%;
        }
        .money-right{
            background-image: url('@/assets/images/ko-5/money_right.png');
            background-size: 100% 100%;
        }
        .money-left, .money-right{
            padding-left: 20px;
            .bottom-title{
                font-family: PingFang SC, PingFang SC;
                font-weight: 600;
                font-size: 18px;
                color: #000000;
                margin-bottom: 14px;
                margin-top: 76px;
                width: 50%;
            }
            .content{
                margin-bottom: 20px;
                width: 50%;
            }
            .jumps a{
                width: 100px;
                height: 36px;
                font-family: PingFang SC, PingFang SC;
                font-weight: 500;
                font-size: 14px;
                color: #000000;
            }
            .jumps a:hover{
                color: #7637BB;
            }
        }
    }
    .palm-quotes{
        width: 100%;
        min-height: 280px;
        margin-top: 140px;
        background-image: url('@/assets/images/ko-5/quotes_bg.png');
        background-size: 100% 100%;
        padding: 60px 35% 0 40px;
        position: relative;
        h2{
            font-family: PingFang SC, PingFang SC;
            font-weight: 600;
            font-size: 26px;
            margin-bottom: 10px;
        }
        .quotes-content{
            font-family: PingFang SC, PingFang SC;
            font-weight: 500;
            font-size: 16px;
            margin-bottom: 10px;
        }
        .quotes-bottom{
            font-family: PingFang SC, PingFang SC;
            font-weight: 500;
            font-size: 15px;
            margin-bottom: 10px;
        }
        .quotes-jump{
            font-family: PingFang SC, PingFang SC;
            font-weight: 500;
            font-size: 15px; 
            color: #7637BB;
            cursor: pointer;
        }
        img{
            position: absolute;
            right: 0px;
            top: -106px;
            width: 400px;
            height: 400px;
        }
    }
    .palm-bottom{
        width: 100%;
        min-height: 280px;
        margin-top: 40px;
        background-image: url('@/assets/images/ko-5/palm-bottom.png');
        background-size: 100% 100%;
        padding: 60px 40px 20px 40px;
        position: relative;
        margin-bottom: 20px;
        h2{
            font-family: PingFang SC, PingFang SC;
            font-weight: 600;
            font-size: 26px;
            margin-bottom: 10px;
        }
        .quotes-content{
            font-family: PingFang SC, PingFang SC;
            font-weight: 500;
            font-size: 16px;
            margin-bottom: 10px;
        }
        .quotes-bottom{
            font-family: PingFang SC, PingFang SC;
            font-weight: 500;
            font-size: 15px;
            margin-bottom: 10px;
        }
        .quotes-jump{
            font-family: PingFang SC, PingFang SC;
            font-weight: 500;
            font-size: 15px; 
            color: #7637BB;
            cursor: pointer;
        }
    }
}


 



@media (max-width: 992px) {
    .container .palm-quotes img{
        position: absolute;
        right: 0px;
        top: -95px;
        width: 290px;
        height: 360px;
    }
}
@media (max-width: 1024px)
{   
    .container{
        .palm-quotes,.palm-bottom{
            display: none;
        }
        .buy-coins{
            background:none;
            flex-flow: column;
            .coins-left,.coins-img{
                width: 100%;
            }
            .coins-left{
                margin-bottom: 30px;
                .jumps{
                    width: 100%;
                    text-align: center;
                    display: flex;
                    justify-content: center;
                }
            }
            .coins-img{
                background-image: url('@/assets/images/ko-5/coins_bg.png');
                background-size: 104% 100%;
            }
        }
        .manage-money{
            .content{
                flex-flow: column;
                .money-left,.money-right{
                    width: 100%;
                    min-height: 500px;
                    padding-left: 0px;
                    border-radius: 7px;
                    .content,.jumps{
                        width: 100%;
                    }
                }
                .money-left{
                    margin-left: 0;
                    margin-bottom: 30px;
                    background: none;
                    .text{
                       min-height: 100px; 
                       padding-top: 20px;
                       background: #f7f6fb;
                       border-top-left-radius: 7px;
                       border-top-right-radius: 7px;
                       padding-left: 20px;
                       .bottom-title{
                            width: 100%;
                            margin-top: 0px;
                        }
                    }
                    .bgimg{
                        height: 400px;
                        background-image: url('@/assets/images/ko-5/money_left_iphone.png');  
                        background-size: 100% 100%;
                    }
                }
                .money-right{
                    margin-left: 0;
                    margin-bottom: 30px;
                    background: none;
                    .text{
                       min-height: 100px; 
                       padding-top: 20px;
                       background: #fbfbff;;
                       border-top-left-radius: 7px;
                       border-top-right-radius: 7px;
                       padding-left: 20px;
                       .bottom-title{
                            width: 100%;
                            margin-top: 0px;
                        }
                    }
                    .bgimg{
                        height: 400px;
                        background-image: url('@/assets/images/ko-5/money_right_iphone.png');  
                        background-size: 100% 100%;
                    }
                }
            }
        }
    }
   
}

 





</style>
