<template>
    <div class="page settings-page">
        <div class="container">
            <div class="row">
                <div class="col">
                    <h1>{{ $t('balance.orders_title') }}</h1>
                </div>
            </div>
            <div class="row">
                <div class="col">
                    <orders-component />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import OrdersComponent from './Components/Orders.vue';
export default {
    components: { OrdersComponent }
};
</script>