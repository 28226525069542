<template>
    <div class="prompted-symbol">
        <div class="title">
            <token-icon :symbol="symbol" />
            <span>{{ symbol.metadata.baseSymbol || symbol.metadata.name }}</span>
            <div  class="percentage" :class="symbol.up ? 'up' : 'down'">{{ numShortener(symbol.price_change_pct.split('%')[0]) }}%</div>
        </div>
        <div class="price">${{ symbol.price }}</div>
        <!-- <div class="token-icon-wrapper d-flex justify-content-center">
            <token-icon :symbol="symbol" />
        </div>
        <div class="symbol-content">
            <h2>{{ symbol.metadata.baseSymbol || symbol.metadata.name }}</h2>
            <div :class="symbol.up ? 'up' : 'down'">{{ numShortener(symbol.price_change_pct.split('%')[0]) }}%</div>
            <div class="price">{{ symbol.price }}</div>
        </div> --> 

        
    </div>
</template>

<script>
import TokenIcon from '../../Components/TokenIcon.vue';

export default {
    components: { TokenIcon },
    props: ['symbol'],

    methods: {
        numShortener: function (num) {
            let result;
            if (!isNaN(num)) {
                num = Number(num);
                result = Math.abs(num) > 999 ? Math.sign(num) * (Math.abs(num) / 1000).toFixed(2) + 'k' : Math.sign(num) * Math.abs(num).toFixed(2);
            } else {
                result = num;
            }
            return result;
        }
    }
};
</script>

<style scoped>
.prompted-symbol{
    width: 100%;
    height: 100px;
    background: #FFFFFF;
    border-radius: 2px;
    padding: 10px 20px;
}
.title{
    display: flex;
    align-items: center;
}
.percentage{
    margin: auto;
    margin-right: 0px;
}
.page-top .prompted-symbol .price{
    background: #fff;
    margin-top: 10px;
    padding-left: 5px;
}

.title .up {
    color: var(--up);
}

.title .up::before {
    content: url('@/assets/images/rising-arrow.png');
    display: inline-block;
    vertical-align: sub;
    height: 0.7rem;
}
.title .down {
    color: var(--down);
}
.title .down::before {
    content: url('@/assets/images/falling-arrow.png');
    display: inline-block;
    vertical-align: sub;
    height: 0.7rem;
}
</style>