<template>
    <section class="page balance-page">
        <div class="top-header w-100">
            <div class="container">
                <div class="row">
                    <div class="col">
                        <div class="top-header-content d-flex align-items-center">
                            <div class="w-100">
                                <h1>{{ $t('assets.title') }}</h1>

                                <!-- Do not display deposit/withdraw buttons when multi-coin supports are enabled. -->
                                <div v-if="bcConfig && !bcConfig.supportMultiCoinDeposits" class="mt-4">
                                    <a href="javascript:;" class="btn btn-primary ps-4 pe-4" @click="toggle_deposit()">
                                        {{ $t('general.deposit') }}
                                    </a>
                                    <router-link class="btn btn-secondary ps-4 pe-4" to="/finance/withdraw">{{ $t('general.withdraw') }}</router-link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <section v-if="bcConfig" class="w-100">
            <div class="container px-3 py-5">
                <div class="row balance-actions">
                    <div class="col">
                        <router-link to="/finance/orders">
                            {{ $t('balance.orders_title') }}
                        </router-link>
                    </div>
                    <div class="col">
                        <router-link to="/finance/history">
                            {{ $t('balance.history_title') }}
                        </router-link>
                    </div>
                    <div class="col">
                        <a href="javascript:;" @click="showExchangeModal">
                            {{ $t('assets.label_exchange') }}
                        </a>
                    </div>
                </div>
                <!-- balance list here -->
                <balance-list-multi-coins v-if="bcConfig.supportMultiCoinDeposits === true" :balance_list="balance_list" :futures_assets="futuresAssets" :bcConfig="bcConfig" />
                <balance-list v-else :balance_list="balance_list" :show_deposit_address="show_deposit_address" :futures_assets="futuresAssets" :bcConfig="bcConfig" />
            </div>

            <futures-stats ref="futuresStats" @balance-updated="onFuturesStatsUpdated" />
            <asset-exchange-modal ref="exchangeModal" :balances="balance_list" :bonusToken="bonusToken" @balance-updated="onBalanceUpdated" />
        </section>
    </section>
</template>

<style scoped>
.page {
    background: var(--bg-primary);
}
</style>

<script>
import AssetExchangeModal from './Components/AssetExchangeModal.vue';
import FuturesStats from '../Components/FuturesOpenOrderStats.vue';
import BalanceList from './BalanceComponents/_BalanceList-4.1.vue';
import BalanceListMultiCoins from './BalanceComponents/_BalanceList-MultiCoins.vue';

let g_assetTypes = [];
let g_assetTypeMap = {};

export default {
    components: { AssetExchangeModal, FuturesStats, BalanceList, BalanceListMultiCoins },

    data() {
        return {
            balance_list: null,
            bonusToken: null,
            futuresAssets: '--',

            // Config settings for blockchain
            bcConfig: null,

            // Indicates whether to display deposit addresses or not.
            show_deposit_address: false
        };
    },

    created() {
        this.initAsync();
    },

    methods: {
        initAsync: async function () {
            ///////////////////////////////////////////////////////////////////////////////////////
            // Get all asset types.
            const assetTypes = [];
            const assetTypeMap = {};

            // USDT and futures margin account will already be displayed at top
            assetTypes.push('USDT');
            assetTypes.push('FTUSDT');
            assetTypeMap['USDT'] = [];
            assetTypeMap['FTUSDT'] = [];

            // Then query blockchain config
            const resp = await $.callPostApi(this, '/api/v1/blockchain/config');
            if (!resp || !resp.data) {
                // exit for invalid config
                console.error('Invalid blockchain config.');
                return;
            }

            if (resp.data.supportedCoins) {
                $(resp.data.supportedCoins).each((index, item) => {
                    // Always use upper case
                    const upperCoin = item.currency.toUpperCase();

                    if (!assetTypeMap[upperCoin]) {
                        assetTypes.push(upperCoin);
                    }
                    assetTypeMap[upperCoin] = item;
                });
            }

            g_assetTypes = Object.freeze(assetTypes);
            g_assetTypeMap = Object.freeze(assetTypeMap);
            this.bonusToken = resp.data.bonusToken;
            this.bcConfig = Object.freeze(resp.data);

            ///////////////////////////////////////////////////////////////////////////////////////
            // Update balance list finally
            await this.syncBalancesAsync();
        },

        toggle_deposit: function () {
            this.show_deposit_address = !this.show_deposit_address;
        },

        syncBalancesAsync: async function () {
            const self = this;
            const balances = await $.callPostApi(this, '/api/v1/balance/list');

            const map = {};
            $(balances).each((index, raw_balance) => {
                map[raw_balance.currency] = new BalanceItem(raw_balance);
            });

            // Have configured tokens display at top
            const output = [];
            $(g_assetTypes).each((index, currency) => {
                let item = map[currency];
                if (!item) {
                    item = new BalanceItem({ currency: currency, balance: 0, frozen: 0 });
                } else {
                    delete map[currency];
                }
                output.push(item);
            });

            // Any remaining balance items?
            for (let currency in map) {
                output.push(map[currency]);
            }

            self.balance_list = output;
        },

        onBalanceUpdated: function () {
            const self = this;
            this.balance_list = null;

            setTimeout(() => {
                // Update balance, and resync open futures orders.
                self.syncBalancesAsync();
                self.$refs.futuresStats.reload();
            }, 500);
        },

        showExchangeModal: function () {
            this.$refs.exchangeModal.showModal();
        },

        /**
         * Update the available amount for the futures account.
         */
        onFuturesStatsUpdated: function () {
            const stats = this.$refs.futuresStats.getStats();
            this.futuresAssets = isNaN(stats.current_balance) ? '--' : stats.current_balance.toFixed(4);
        },

    }
};

/**
 * BalanceItem
 */
function BalanceItem(balance) {
    // this.__raw_data = balance;

    // is the symbol associated to an exchange one?
    // const sym = _exchange_symbol_name_map[balance.currency];
    // const precision = sym ? sym.volumePrecision : 2;

    // hard-coded to use 4 precision
    const precision = 4;

    this.available = (balance.balance + balance.frozen).formatBalance(precision);
    this.balance = balance.balance.formatBalance(precision);
    this.frozen = (-balance.frozen).formatBalance(precision);
    // this.incentive = balance.incentive.floor(precision) * 1;

    this.display_name = balance.displayName || balance.currency;
    this.currency = balance.currency;
}
</script>