<template>
    <!-- Nothing to render for the component -->
    <div class="d-none"></div>
</template>

<!--
A script to update futures balance.
-->
<script>
let g_balance = null;
let g_destroyed = true;
let g_quote_timer = 0;
let g_stats_timer = 0;

export default {
    data() {
        return {
            // All open orders
            orders: null,

            // Balance stats
            current_balance: NaN,
            risk_rate: NaN,
            used_margin: NaN,
            frozen_margin: NaN,
            total_profit: NaN
        };
    },

    created() {
        g_destroyed = false;

        // Query open orders from server side.
        this._doReadStats();
    },

    beforeDestroy() {
        g_destroyed = true;

        clearTimeout(g_quote_timer);
        clearTimeout(g_stats_timer);
    },

    methods: {
        /**
         * Force to reload open orders.
         */
        reload: function () {
            clearTimeout(g_stats_timer);

            const self = this;
            if (g_destroyed === false) {
                g_stats_timer = setTimeout(self._doReadStats, 1000);
            }
        },

        getOrders: function () {
            return this.orders;
        },

        getStats: function () {
            return {
                current_balance: this.current_balance,
                risk_rate: this.risk_rate,
                used_margin: this.used_margin,
                frozen_margin: this.frozen_margin,
                total_profit: this.total_profit
            };
        },

        ///////////////////////////////////////////////////////////////////////////////////////////
        /**
         * Sync open orders and balance every 10 seconds.
         */
        _doReadStats: function () {
            const self = this;
            clearTimeout(g_stats_timer);

            $.post('/api/v1/futures/stats')
                .done(function (stats) {
                    // update quotes
                    g_balance = Object.freeze(stats.balance);

                    // Remember all existing orders.
                    const current_map = {};
                    if (self.orders) {
                        $(self.orders).each((_, order) => {
                            current_map[order.orderId] = order;
                        });
                    }

                    const output = [];
                    if (stats.openOrders) {
                        $(stats.openOrders).each((_, order) => {
                            if (current_map[order.orderId]) {
                                // Do not replace existing open orders.
                                // Otherwise, its realtime price/profit information will be lost.
                                // And most importantly, it won't be updated in popup modals like Close Order modal.
                                //
                                output.push(current_map[order.orderId]);
                            } else {
                                output.push(order);
                            }
                        });
                    }
                    self.orders = output;

                    if (self.orders.length > 0) {
                        // Start to read realtime quotes
                        self._doReadQuotes();

                        // update risk rate for the 1st time
                        self._updateRiskRate();
                    } else {
                        self._updateRiskRate(0);
                    }

                    ///////////////////////////////////////////////////////////////////
                    // Notify the update of data-update
                    self.$emit('stats-updated');
                    self.$emit('balance-updated');
                })
                .always(function () {
                    clearTimeout(g_stats_timer);

                    if (g_destroyed === false) {
                        g_stats_timer = setTimeout(self._doReadStats, 10000);
                    }
                });
        },

        ///////////////////////////////////////////////////////////////////////////////////////////
        /**
         * Sync quote for all open orders every 5 seconds.
         */
        _doReadQuotes: function () {
            const self = this;

            if (!this.orders) {
                clearTimeout(g_quote_timer);

                if (g_destroyed === false) {
                    g_quote_timer = setTimeout(self._doReadQuotes, 2000);

                    ///////////////////////////////////////////////////////////////////
                    // Notify the update of balance & risk rate
                    self.$emit('balance-updated');
                }
            } else {
                // get an unique set of symbols
                const map = {};
                const sids = [];

                for (let i = 0; i < this.orders.length; i++) {
                    const sid = this.orders[i].symbolId;
                    if (map[sid] !== true) {
                        sids.push(sid);
                        map[sid] = true;
                    }
                }

                if (sids.length > 0) {
                    $.get('/api/v1/quotation/latest?symbols=' + sids.join(','))
                        .done(function (quotes) {
                            // update quotes
                            for (let i = 0; i < quotes.length; i++) {
                                self._updateRealtimePrice(quotes[i].id, quotes[i].c);
                            }

                            ///////////////////////////////////////////////////////////////////
                            // Notify the update of balance & risk rate
                            self.$emit('balance-updated');
                        })
                        .always(function () {
                            // refresh quotes in 5 seconds.
                            clearTimeout(g_quote_timer);

                            if (g_destroyed === false) {
                                g_quote_timer = setTimeout(self._doReadQuotes, 5000);
                            }
                        });
                } else {
                    // In case some open order were created somewhere else.
                    clearTimeout(g_quote_timer);

                    if (g_destroyed === false) {
                        g_quote_timer = setTimeout(self._doReadQuotes, 2000);
                    }
                }
            }
        },

        _updateRealtimePrice: function (sid, price) {
            let totalProfit = 0;

            for (let i = 0; i < this.orders.length; i++) {
                const order = this.orders[i];
                if (order.symbolId === sid) {
                    let profit = (price / order.matchPrice - 1) * order.numHands * 1000;
                    if (!order.buyUp) profit = -profit;

                    // Update display price and profit
                    order.currentPrice = price;
                    order.currentProfit = profit;

                    totalProfit += profit;
                } else {
                    totalProfit += order.currentProfit;
                }

                // Mising quotes for some symbols
                if (isNaN(totalProfit)) break;
            }

            this._updateRiskRate(totalProfit);
        },

        _updateRiskRate: function (total_profit) {
            let used_margin = 0;

            for (let i = 0; i < this.orders.length; i++) {
                const order = this.orders[i];

                // gets the amount of margin for the order.
                const margin = (order.numHands * 1000) / order.leverage;
                used_margin += margin;
            }

            this.total_profit = total_profit;
            this.used_margin = used_margin.toFixed(2) * 1;
            if (!g_balance) {
                this.frozen_margin = NaN;
                this.current_balance = NaN;
                this.risk_rate = NaN;
            } else {
                this.frozen_margin = Math.max(0, -g_balance.frozen - used_margin).toFixed(2) * 1;

                if (typeof total_profit === 'undefined') {
                    // Unknown profit
                    this.risk_rate = NaN;
                    this.current_balance = NaN;
                }
                if (used_margin <= 0) {
                    // No open orders
                    this.risk_rate = 1;
                    this.current_balance = g_balance.balance;
                } else {
                    // 風險占比 =｛（交易可用金+交易占用金）/10+未平倉損益｝/（交易占用金/10）*100%
                    let risk_rate = (g_balance.balance + g_balance.frozen + total_profit + this.used_margin) / this.used_margin;

                    this.current_balance = g_balance.balance + total_profit + this.used_margin;
                    this.risk_rate = risk_rate;
                }
            }
        }
    }
};
</script>