<template>
    <div ref="createOrderModal" class="modal fade" tabindex="-1" role="dialog">
        <div class="modal-dialog" role="document">
            <div class="modal-content">
                <form method="post" action="javascript:void(0)" @submit="doCreateOrder">
                    <input type="hidden" name="SymbolId" :value="symbol.metadata.id" />
                    <input type="hidden" name="BuyUp" v-bind:value="buyUp" />

                    <input type="hidden" name="Leverage" v-bind:value="leverage" />
                    <input type="hidden" name="NumHands" v-bind:value="numHands" />

                    <!-- do not support pending orders for now -->
                    <input type="hidden" name="CreatePrice" value="-1" />

                    <div class="modal-header" :class="{ 'bg-up': buyUp, 'bg-down': !buyUp }">
                        <h4 class="modal-title" v-if="buyUp">{{ $t('futures.label_buy_up_long') }} - {{ symbol.metadata.name }}</h4>
                        <h4 class="modal-title" v-if="!buyUp">{{ $t('futures.label_buy_down_long') }} - {{ symbol.metadata.name }}</h4>
                    </div>
                    <div class="modal-body d-flex">
                        <dl class="col">
                            <dt class="text-label">{{ $t('futures.label_amount') }}</dt>
                            <dd class="text-content">{{ numHands }} {{ $t('futures.label_unit') }}</dd>
                        </dl>
                        <dl class="col">
                            <dt class="text-label">{{ $t('futures.label_margin') }}</dt>
                            <dd class="text-content">{{ 100 / leverage }}%</dd>
                        </dl>
                        <dl class="col">
                            <dt class="text-label">{{ $t('futures.label_price') }}</dt>
                            <dd class="text-content">{{ $t('futures.label_market_price') }}</dd>
                        </dl>
                    </div>

                    <!-- Buttons at bottom -->
                    <div class="modal-footer">
                        <button type="button" class="btn btn-default" data-bs-dismiss="modal">{{ $t('general.cancel') }}</button>
                        <button type="submit" class="btn btn-primary bg-up" v-if="buyUp" :disabled="submitting" :class="{ 'btn-loading': submitting }">
                            {{ $t('futures.confirm_order') }}
                        </button>
                        <button type="submit" class="btn btn-primary bg-down" v-if="!buyUp" :disabled="submitting" :class="{ 'btn-loading': submitting }">
                            {{ $t('futures.confirm_order') }}
                        </button>
                    </div>
                </form>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios';
import { Modal } from 'bootstrap';

export default {
    props: ['symbol'],

    data() {
        return {
            // Order options
            buyUp: true,
            numHands: NaN,
            leverage: NaN,

            // Indicating whether there is an on-going operation or not
            submitting: false,

            // The popup confirmation modal.
            popupModal: null
        };
    },

    mounted() {
        this.popupModal = new Modal(this.$refs.createOrderModal, { dismissible: false });
    },

    beforeDestroy() {
        const m = this.popupModal;
        if (m) {
            m.dispose();
        }
    },

    methods: {
        /**
         * Assign parameters and open a confirmation modal.
         */
        openModal: function (order) {
            if (!order || typeof order.buyUp !== 'boolean' || typeof order.numHands !== 'number' || typeof order.leverage !== 'number') {
                throw new Error('Invalid parameters.');
            }

            this.buyUp = order.buyUp;
            this.numHands = order.numHands;
            this.leverage = order.leverage;

            // const self = this;
            // Vue.nextTick(() => {
            //     $(self.$refs.createOrderModal).modal({ dismissible: false }).modal('show');
            // });
            this.popupModal.show();
        },

        /**
         * Will be called after clicking a submit button.
         */
        doCreateOrder: function (e) {
            const self = this;
            const frm = $(e.target);
            const data = frm.serialize();

            self.submitting = true;

            // submit the order to server
            axios
                .post('/api/v1/futures/create', data)
                .then((resp) => {
                    const json = resp.data;

                    const _callback = function () {
                        self.submitting = false;

                        if (json.errcode === 0) {
                            // $(self.$refs.createOrderModal).modal('hide');
                            self.popupModal.hide();
                            $.top_alert(self.$t('general.submitted'));

                            // Emit an event to indicate that an order has been created.
                            self.$emit('order-created');
                        } else {
                            $.top_error(json.errmsg);
                        }
                    };

                    // display the result in a second or so
                    setTimeout(_callback, 500);
                })
                .catch((err) => {
                    const _callback = function () {
                        self.submitting = false;
                        $.top_error(self.$t('general.operation_error'));

                        console.log('Failed to create an order' + JSON.stringify(e));
                    };
                    setTimeout(_callback, 500);
                });
        }
    }
};
</script>