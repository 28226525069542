<template>
    <div class="marquee">
        <ul class="marquee__content" :style="`animation-duration: ${promptedNotices.length * 10}s;`">
            <li v-for="notice in promptedNotices" :key="notice.id">
                <router-link :to="'/notice/' + notice.id" rel="noopener noreferrer nofollow">
                    <div v-html="notice.title"></div>
                </router-link>
            </li>
        </ul>

        <ul aria-hidden="true" class="marquee__content" :style="`animation-duration: ${promptedNotices.length * 10}s;`">
            <li v-for="notice in promptedNotices" :key="notice.id">
                <router-link :to="'/notice/' + notice.id" rel="noopener noreferrer nofollow">
                    <div v-html="notice.title"></div>
                </router-link>
            </li>
        </ul>
    </div>
</template>

<style scoped>
/* Marquee styles */
.marquee {
    --gap: 5rem;
    position: relative;
    display: flex;
    overflow: hidden;
    user-select: none;
    gap: var(--gap);
    height: 100%;
}
.marquee li a{
    height: 100%;
    display: flex;
    align-items: center;
    color: #fff;
} 
.marquee__content {
    flex-shrink: 0;
    display: flex;
    justify-content: space-around;
    gap: var(--gap);
    min-width: 100%;
    list-style-type: none;

    animation: scroll linear infinite;
    /* animation-duration: 20s; */
}

@keyframes scroll {
    from {
        transform: translateX(0);
    }

    to {
        transform: translateX(calc(-100% - var(--gap)));
    }
}
</style>

<script>
export default {
    props: ['promptedNotices']
}
</script>